import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ModalService } from "../services/modal.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  public user: any = {};

  constructor(
    public router: Router,
    private authService: AuthService,
    private modalService: ModalService
  ) {}

  async canActivate() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.router.navigate(["/auth"]);
      return false;
    }
    let size = this.verifySize();
    this.user = (await this.authService.getUserInfo())["user_data"];
    if (
      navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i) ||
      size[0] < 960
    ) {
      window.location.href = "https://develop-flecha-app.imagineapps.co/";
      return false;
    } else if (!this.user.accept_terms_conditions) {
      this.modalService.openModal({
        modal: "Terms & Conditions",
      });
      return true;
    } else if (token) {
      return true;
    } else {
      this.router.navigate(["/auth"]);
      return false;
    }
  }

  /**
   * verify the width and height of the screen.
   */
  public verifySize() {
    var tam = [0, 0];
    if (typeof window.innerWidth != "undefined") {
      tam = [window.innerWidth, window.innerHeight];
    } else if (
      typeof document.documentElement != "undefined" &&
      typeof document.documentElement.clientWidth != "undefined" &&
      document.documentElement.clientWidth != 0
    ) {
      tam = [
        document.documentElement.clientWidth,
        document.documentElement.clientHeight,
      ];
    } else {
      tam = [
        document.getElementsByTagName("body")[0].clientWidth,
        document.getElementsByTagName("body")[0].clientHeight,
      ];
    }
    return tam;
  }
}
