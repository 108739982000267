import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "src/app/core/services/auth.service";
import { MainService } from "src/app/core/services/main.service";
import * as helper from "src/app/core/utils/helper";

@Component({
  selector: "app-modal-add-money",
  templateUrl: "./modal-add-money.component.html",
  styleUrls: ["./modal-add-money.component.scss"],
})
export class ModalAddMoneyComponent implements OnInit {
  public user: any = [];
  public widgetUrl;
  public usd = helper.usd;
  public lumens = helper.lumens;
  public lumensAsset: any = {};
  public loading = true;
  public usdAmount: number = 0;
  public lumensAmount: number = 0;

  @Output() close = new EventEmitter<string>();
  @Output() toast = new EventEmitter<string>();

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.user = (await this.authService.getUserInfo())["user_data"];
    this.getBalance();
  }

  public openWyreWindow() {
    if (this.usdAmount > 0) {
      this.loading = true;
      this.mainService
        .post({
          api: "wyre/wyre-widget",
          data: { sourceAmount: this.usdAmount, destAmount: "" },
        })
        .subscribe((response) => {
          window.open(response["url"]);
          this.loading = false;
          this.close.emit("Hide");
        });
    } else {
      this.toast.emit("The minimum amount is $1 USD");
    }
  }

  public onClose() {
    this.close.emit("Hide");
  }

  public onExchange() {
    this.usdAmount = Number(helper.formatterNumbers(this.usdAmount));

    this.lumensAmount = Number(
      helper.removeDecimals(this.usdAmount / this.lumensAsset.USDExchange, 2)
    );
  }

  public getBalance() {
    this.mainService
      .get({ api: `stellar/get-currencies-to-received-exchange` })
      .subscribe((results) => {
        //Get general balance
        this.lumensAsset = results.data.currenciesToSwapExchange.find(
          (e) => e.asset_code == "Lumens"
        );
        this.loading = false;
      });
  }
}
