import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MainService } from "src/app/core/services/main.service";
import { SettingsService } from "src/app/core/services/settings.service";
import * as helper from "src/app/core/utils/helper";

@Component({
  selector: "app-modal-get-secret-key",
  templateUrl: "./modal-get-secret-key.component.html",
  styleUrls: ["./modal-get-secret-key.component.scss"],
})
export class ModalGetSecretKeyComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();
  @Output() toast = new EventEmitter();

  public widgetUrl;
  public currentPassword: string = "";
  public showPassword: string = "password";
  public verificationCode: string = "";
  public privateKey: string = "";
  public loading: boolean = false;
  public step = "Disclaimer"; //Disclaimer, Password, Verification code, Private Key

  constructor(private mainService: MainService) {}

  async ngOnInit() {}

  public onClose() {
    this.close.emit("Hide");
  }

  public onValidateCode() {
    this.verificationCode = helper
      .formatterNumbers(this.verificationCode)
      .replace(" ", "")
      .substring(0, 4);
  }

  public onNextStep() {
    switch (this.step) {
      case "Disclaimer":
        this.step = "Password";
        break;
      case "Password":
        this.verifyPassword();
        break;
      case "Verification code":
        this.verifyCode();
      case "Private Key":
        this.onCopy({ text: this.privateKey });
        break;
    }
  }

  public verifyPassword() {
    this.loading = true;
    this.mainService
      .post({
        api: "users/first-factor-authentication-secret-id",
        data: { password: this.currentPassword },
      })
      .subscribe(
        (response) => {
          this.loading = false;
          this.step = "Verification code";
        },
        (err) => {
          console.log(err);
          this.loading = false;
          this.toast.emit(err.msg);
        }
      );
  }

  public verifyCode() {
    this.loading = true;
    this.mainService
      .post({
        api: "users/second-factor-authentication-secret-id",
        data: { verification_code: this.verificationCode },
      })
      .subscribe(
        (response) => {
          this.loading = false;
          this.privateKey = response.private_key;
          this.step = "Private Key";
        },
        (err) => {
          console.log(err);
          this.loading = false;
          this.toast.emit(err.msg);
        }
      );
  }

  public onCopy({ text }) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toast.emit("Copied to clipboard!");
  }
}
