import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MainService } from "src/app/core/services/main.service";
import { ModalService } from "src/app/core/services/modal.service";

@Component({
  selector: "app-modal-view-recipient",
  templateUrl: "./modal-view-recipient.component.html",
  styleUrls: ["./modal-view-recipient.component.scss"],
})
export class ModalViewRecipientComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();
  @Input() contact;
  public external: boolean = false;
  public nickname: string = "";
  public id: string = "";

  constructor(
    private mainService: MainService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {}

  public onClose() {
    this.close.emit("Hide");
  }

  public onDeleteRecipient() {
    this.mainService
      .post({
        api: "recipients/delete-recipient",
        data: { recipient_id: this.contact._id },
      })
      .subscribe(
        (response) => {
          this.modalService.addRecipient({
            reload: true,
          });
          this.success.emit(response);
        },
        (err) => {
          this.error.emit(err.error);
          this.modalService.addRecipient({
            reload: false,
          });
        }
      );
  }
}
