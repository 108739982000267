export const removeDecimals = (number: number, spaces: number) => {
  let formatNumber = number.toString();
  if (formatNumber.indexOf(".") != -1)
    formatNumber = formatNumber.slice(
      0,
      formatNumber.indexOf(".") + spaces + 1
    );
  return Number(formatNumber);
};
/** Function to remplace special charaters and letters of string */
export const formatterNumbers = (baseText) =>
  baseText
    .toString()
    .replace(/[^\w\s]/gi, "")
    .replace(/\D/g, "");

export const usd = "./assets/general/usd.png";
export const lumens = "./assets/general/lumens.png";

export const getIconUrl = (code) => {
  switch (code) {
    case "Lumens":
      return "./assets/general/lumens.png";
    case "ARS":
      return "./assets/general/arst.png";
    case "BRL":
      return "./assets/general/brl.png";
    case "USDC":
      return "./assets/general/usdc.png";
    default:
      return "./assets/general/lumens.png";
  }
};

export const options = {
  bezierCurve: false,
  elements: { line: { tension: 0 }, point: { radius: 0 } },
  legend: { display: false },
  scales: {
    yAxes: [
      {
        display: true,
        ticks: {
          fontColor: "#1d242c",
        },
        gridLines: { display: true, color: "#1d242c" },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          color: "#1d242c",
          drawBorder: false,
        },
      },
    ],
  },
};
