import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ModalService } from "src/app/core/services/modal.service";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-navigation-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"],
})
export class NavigationBarComponent implements OnInit {
  public activatedMenu = "Wallet";
  readonly MENU_ITEMS = [
    {
      title: "Wallet",
      iconOff: "./assets/icons/wallet-off.png",
      iconOn: "./assets/icons/wallet-on.png",
      selected: false,
    },
    // {
    //   title: "Withdrawal",
    //   iconOff: "./assets/icons/withdrawal-off.png",
    //   iconOn: "./assets/icons/withdrawal-on.png",
    //   selected: false,
    // },
    {
      title: "Transfer",
      iconOff: "./assets/icons/transfer-off.png",
      iconOn: "./assets/icons/transfer-on.png",
      selected: false,
    },
    {
      title: "Convert Crypto",
      iconOff: "./assets/icons/transfer-off.png",
      iconOn: "./assets/icons/transfer-on.png",
      selected: false,
    },
    {
      title: "Add Money",
      iconOff: "./assets/icons/add-money-off.png",
      iconOn: "./assets/icons/add-money-on.png",
      selected: false,
    },
    {
      title: "History",
      iconOff: "./assets/icons/history-off.png",
      iconOn: "./assets/icons/history-on.png",
      selected: false,
    },
  ];

  constructor(
    private router: Router,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.changeUnexpectedItem({ url: this.router.url.replace("/", "") });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.changeUnexpectedItem({ url: event.url.replace("/", "") });
      });
  }

  public onSelectItem({ item }) {
    switch (item.title) {
      case "Wallet":
        this.activatedMenu = item.title;
        this.router.navigate(["/wallet"]);
        break;
      case "Transfer":
        this.activatedMenu = item.title;
        this.router.navigate(["/transfer"]);
        break;
      case "Add Money":
        this.modalService.openModal({
          modal: "Add money",
        });
        break;
      case "Convert Crypto":
        this.modalService.openModal({
          modal: "Convert Crypto",
        });
        break;
      case "History":
        this.activatedMenu = item.title;
        this.router.navigate(["/history"]);
        break;

      default:
        alert("Module disabled");
        break;
    }
  }

  public changeUnexpectedItem({ url }) {
    switch (url) {
      case "wallet":
        this.activatedMenu = "Wallet";
        break;
      case "transfer":
        this.activatedMenu = "Transfer";
        break;
      case "profile":
        this.activatedMenu = "Profile";
        break;
      case "history":
        this.activatedMenu = "History";
        break;
    }
  }
}
