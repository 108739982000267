import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./layout/header/header.component";
import { NavigationBarComponent } from "./layout/navigation-bar/navigation-bar.component";
import { ModalAddMoneyComponent } from "./components/modal-add-money/modal-add-money.component";
import { ToastComponent } from "./components/toast/toast.component";
import { ModalChangePasswordComponent } from "./components/modal-change-password/modal-change-password.component";
import { ModalSuccessComponent } from "./components/modal-success/modal-success.component";
import { ModalErrorComponent } from "./components/modal-error/modal-error.component";
import { ModalContactUsComponent } from "./components/modal-contact-us/modal-contact-us.component";
import { ModalAddRecipientComponent } from "./components/modal-add-recipient/modal-add-recipient.component";
import { ModalViewRecipientComponent } from "./components/modal-view-recipient/modal-view-recipient.component";
import { StepperComponent } from "./components/stepper/stepper.component";
import { ModalConfirmTransactionComponent } from "./components/modal-confirm-transaction/modal-confirm-transaction.component";
import { ModalSwapAssetsComponent } from "./components/modal-swap-assets/modal-swap-assets.component";
import { NgxLoadingModule } from "ngx-loading";
import { ModalTryAgainComponent } from "./components/modal-try-again/modal-try-again.component";
import { ModalAcceptTermsConditionsComponent } from "./components/modal-accept-terms-conditions/modal-accept-terms-conditions.component";
import { ModalGetSecretKeyComponent } from "./components/modal-get-secret-key/modal-get-secret-key.component";
import { ContactComponent } from "./components/contact/contact.component";

@NgModule({
  declarations: [
    HeaderComponent,
    NavigationBarComponent,
    ModalAddMoneyComponent,
    ToastComponent,
    ModalChangePasswordComponent,
    ModalSuccessComponent,
    ModalErrorComponent,
    ModalContactUsComponent,
    ModalAddRecipientComponent,
    ModalViewRecipientComponent,
    StepperComponent,
    ModalConfirmTransactionComponent,
    ModalSwapAssetsComponent,
    ModalTryAgainComponent,
    ModalAcceptTermsConditionsComponent,
    ModalGetSecretKeyComponent,
    ContactComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
  ],
  exports: [
    HeaderComponent,
    NavigationBarComponent,
    ModalAddMoneyComponent,
    ToastComponent,
    ModalChangePasswordComponent,
    ModalSuccessComponent,
    ModalErrorComponent,
    ModalContactUsComponent,
    ModalAddRecipientComponent,
    ModalViewRecipientComponent,
    StepperComponent,
    ModalConfirmTransactionComponent,
    ModalSwapAssetsComponent,
    ModalTryAgainComponent,
    ModalAcceptTermsConditionsComponent,
    ModalGetSecretKeyComponent,
    ContactComponent,
  ],
})
export class SharedModule {}
