import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authToken: any;
  public headers: HttpHeaders;
  public baseUrl: string = "";

  constructor(private http: HttpClient, private router: Router) {
    this.setConfig();
  }

  public setConfig() {
    this.baseUrl = environment.baseUrl;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set("Content-Type", "application/json");
  }

  public authenticateUser({ email, password }) {
    return this.http.post(
      `${this.baseUrl}auth/login`,
      { email, password },
      { headers: this.headers }
    );
  }

  public verifyCode({ email, verification_code }) {
    return this.http.post(
      `${this.baseUrl}auth/validate-SMS-Code`,
      { email, verification_code },
      { headers: this.headers }
    );
  }

  public resendCode({ email }) {
    return this.http.post(
      `${this.baseUrl}auth/resend-code`,
      { email },
      { headers: this.headers }
    );
  }

  public forgotPassword({ email }) {
    return this.http.post(
      `${this.baseUrl}auth/recover-password`,
      { email },
      { headers: this.headers }
    );
  }

  public storeToken({ token }) {
    localStorage.setItem("token", token);
    this.authToken = token;
  }

  public storeEmail({ email }) {
    localStorage.setItem("user_email", email);
  }

  public getUserInfo() {
    const token = localStorage.getItem("token");
    this.headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      authorization: token || "",
    });
    return this.http
      .get(`${this.baseUrl}users/user-data`, { headers: this.headers })
      .toPromise();
  }

  public logout() {
    this.authToken = null;
    localStorage.clear();
    this.router.navigate(["/auth"]);
  }

  public changePassword({ email, password, newPassword }) {
    const data = { email, password, newPassword };
    return this.http.post(
      `${this.baseUrl}auth/change-password`,
      JSON.stringify(data),
      {
        headers: this.headers,
      }
    );
  }
}
