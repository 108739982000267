import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MainService } from "src/app/core/services/main.service";
import { ModalService } from "src/app/core/services/modal.service";

@Component({
  selector: "app-modal-confirm-transaction",
  templateUrl: "./modal-confirm-transaction.component.html",
  styleUrls: ["./modal-confirm-transaction.component.scss"],
})
export class ModalConfirmTransactionComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();
  @Input() transaction: any;
  public loading = false;

  constructor(
    private mainService: MainService,
    private modalService: ModalService,
    public router: Router
  ) {}

  async ngOnInit() {}

  public onClose() {
    this.close.emit("Hide");
  }

  public onConfirmTransaction() {
    this.loading = true;
    this.mainService
      .post({ api: "transactions/send-payment", data: this.transaction })
      .subscribe(
        (response) => {
          this.loading = false;
          this.modalService.addRecipient({
            reload: true,
          });
          this.router.navigate(["/wallet"]);
          this.success.emit(response);
        },
        (err) => {
          console.log(err);
          this.loading = false;

          this.error.emit(err.error);
          this.modalService.addRecipient({
            reload: false,
          });
        }
      );
  }
}
