import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { MainService } from "src/app/core/services/main.service";
import { SettingsService } from "src/app/core/services/settings.service";

@Component({
  selector: "app-modal-contact-us",
  templateUrl: "./modal-contact-us.component.html",
  styleUrls: ["./modal-contact-us.component.scss"],
})
export class ModalContactUsComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();

  public user: any = {};
  public issueTypes: any = [];
  public issueType: string = "";
  public message: string = "";

  constructor(
    private authService: AuthService,
    private mainService: MainService,
    private settingsService: SettingsService
  ) {}

  async ngOnInit() {
    this.user = (await this.authService.getUserInfo())["user_data"];
    this.getIssueList();
  }

  public getIssueList() {
    this.settingsService.getContactMessage().subscribe((response: any) => {
      this.issueTypes = response.types;
    });
  }

  public onSendMessage() {
    let data = {
      message_type: this.issueType,
      message_status: "Pending",
      message: this.message,
    };
    this.mainService
      .post({ api: "contact-message/send-new-contact-message", data: data })
      .subscribe(
        (response) => this.success.emit(response),
        (err) => this.error.emit(err.error)
      );
  }

  public onClose() {
    this.close.emit("Hide");
  }
}
