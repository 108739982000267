import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MainService } from "src/app/core/services/main.service";
import { ModalService } from "src/app/core/services/modal.service";

@Component({
  selector: "app-modal-add-recipient",
  templateUrl: "./modal-add-recipient.component.html",
  styleUrls: ["./modal-add-recipient.component.scss"],
})
export class ModalAddRecipientComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();
  public external: boolean = false;
  public nickname: string = "";
  public id: string = "";

  constructor(
    private mainService: MainService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {}

  public onClose() {
    this.close.emit("Hide");
  }

  public onAddRecipient() {
    let data: any = {
      nick_name: this.nickname,
      external_recipient_confirmation: this.external,
    };
    data[this.external ? "wallet_id" : "federation_address"] = this.id;
    if (this.nickname && this.id) {
      this.mainService
        .post({ api: "recipients/create-recipient", data })
        .subscribe(
          (response) => {
            this.modalService.addRecipient({
              reload: true,
            });
            this.success.emit(response);
          },
          (err) => {
            this.error.emit(err.error);
            this.modalService.addRecipient({
              reload: false,
            });
          }
        );
    } else {
      alert("Please complete the fields");
    }
  }
}
