import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  /** API Access - ENV */
  public baseUrl: string = "";
  /** Variable that holds the http headers */
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });
  }

  /**
   * API Generico GET
   * @param {string} api api path
   * @return {Observable<any>} respuesta asincrónica
   */
  getCountries(): Observable<any> {
    return this.http.get(`${this.baseUrl}countries/get-countries`, {
      headers: this.headers,
    });
  }

  getCurrencies(): Observable<any> {
    return this.http.get(`${this.baseUrl}currencies/get-currencies`, {
      headers: this.headers,
    });
  }

  getTermsAndConditions(): Observable<any> {
    return this.http.get(`${this.baseUrl}terms-conditions/get-legal-info`, {
      headers: this.headers,
    });
  }
  getFaq(): Observable<any> {
    return this.http.get(`${this.baseUrl}faq/get-faq`, {
      headers: this.headers,
    });
  }

  getContactMessage(): Observable<any> {
    return this.http.get(
      `${this.baseUrl}contact-message/get-contact-message-types`,
      {
        headers: this.headers,
      }
    );
  }
}
