import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MainService } from "src/app/core/services/main.service";
import { ModalService } from "src/app/core/services/modal.service";
import * as helper from "src/app/core/utils/helper";

@Component({
  selector: "app-modal-swap-assets",
  templateUrl: "./modal-swap-assets.component.html",
  styleUrls: ["./modal-swap-assets.component.scss"],
})
export class ModalSwapAssetsComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();

  public usd = helper.usd;
  public lumens = helper.lumens;

  public cryptoConvert: any = { img: helper.lumens };
  public cryptoConvertCode: string = "";
  public cryptoConvertAmount: number = 0;
  public cryptoReceive: any = { img: helper.lumens };
  public cryptoReceiveCode: string = "";
  public cryptoReceiveAmount: number = 0;
  public lumensAsset: any = {};
  public lumensAmount: number = 0;
  public usdAmount: number = 0;
  public usdView: number = 0;

  public toast: boolean = false;

  public balance = [];
  public loading = true;
  public message = "";

  constructor(
    private mainService: MainService,
    private modalService: ModalService
  ) {
    this.getBalance();
  }

  async ngOnInit() {}

  public onClose() {
    this.close.emit("Hide");
  }

  public getBalance() {
    this.mainService
      .get({ api: `stellar/get-currencies-to-received-exchange` })
      .subscribe((results) => {
        //Get general balance
        this.balance = results.success
          ? results.data.currenciesToSwapExchange
          : [];
        this.balance.forEach((e) => (e.img = helper.getIconUrl(e.asset_code)));
        this.cryptoConvert = this.balance[0];
        this.cryptoConvertCode = this.balance[0].asset_code;
        this.cryptoReceive = this.balance[0];
        this.cryptoReceiveCode = this.balance[0].asset_code;
        this.lumensAsset = this.balance.find((e) => e.asset_code == "Lumens");

        this.loading = false;
      });
  }

  public onChangeCoin({ code, crypto }) {
    switch (crypto) {
      case "Convert":
        this.cryptoConvert = this.balance.find((e) => e.asset_code == code);
        break;
      case "Receive":
        this.cryptoReceive = this.balance.find((e) => e.asset_code == code);
        break;
    }
    this.onExchange({ type: "Convert" });
  }

  public onExchange({ type }) {
    switch (type) {
      case "Convert":
        this.usdView = helper.removeDecimals(
          this.cryptoConvertAmount * this.cryptoConvert.USDExchange,
          3
        );
        this.usdAmount =
          this.cryptoConvertAmount * this.cryptoConvert.USDExchange;
        this.cryptoReceiveAmount =
          this.usdAmount / this.cryptoReceive.USDExchange;
        break;
      case "Receive":
        this.usdView = helper.removeDecimals(
          this.cryptoReceiveAmount * this.cryptoReceive.USDExchange,
          3
        );
        this.usdAmount =
          this.cryptoReceiveAmount * this.cryptoReceive.USDExchange;
        this.cryptoConvertAmount =
          this.usdAmount / this.cryptoConvert.USDExchange;
        break;
    }
    this.lumensAmount = helper.removeDecimals(
      this.usdAmount / this.lumensAsset.USDExchange,
      3
    );
  }

  public onConfirmTransaction() {
    this.loading = true;
    let data = {
      amount: this.cryptoConvertAmount.toString(),
      asset_sended_code: this.cryptoConvert.asset_code,
      asset_received_code: this.cryptoReceive.asset_code,
    };
    this.mainService.post({ api: "transactions/swap-assets", data }).subscribe(
      (response) => {
        this.loading = false;
        this.modalService.addRecipient({
          reload: true,
        });
        this.success.emit(response);
      },
      (err) => {
        console.log(err);
        this.loading = false;
        this.error.emit(err.error);
        this.modalService.addRecipient({
          reload: false,
        });
      }
    );
  }
}
