import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { LoggedGuard } from "./core/guards/logged.guard";
import { LayoutComponent } from "./layout/layout.component";
import { HistoryMainComponent } from "./history/components/history-main/history-main.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "/wallet",
        pathMatch: "full",
      },
      {
        path: "wallet",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./wallet/wallet.module").then((m) => m.WalletModule),
      },
      {
        path: "transfer",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./transfer/transfer.module").then((m) => m.TransferModule),
      },
      {
        path: "profile",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "history",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./history/history.module").then((m) => m.HistoryModule),
      },
    ],
  },
  {
    path: "auth",
    canActivate: [LoggedGuard],
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
