import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-modal-try-again",
  templateUrl: "./modal-try-again.component.html",
  styleUrls: ["./modal-try-again.component.scss"],
})
export class ModalTryAgainComponent implements OnInit {
  @Input() try;
  @Output() close = new EventEmitter<string>();
  @Output() tryAgain = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  public onTryAgain() {
    this.tryAgain.emit(this.try.modal);
  }
  public onClose() {
    this.close.emit("Hide");
  }
}
