import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MainService } from "src/app/core/services/main.service";
import { SettingsService } from "src/app/core/services/settings.service";
import * as helper from "src/app/core/utils/helper";

@Component({
  selector: "app-modal-accept-terms-conditions",
  templateUrl: "./modal-accept-terms-conditions.component.html",
  styleUrls: ["./modal-accept-terms-conditions.component.scss"],
})
export class ModalAcceptTermsConditionsComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() success = new EventEmitter();
  @Output() error = new EventEmitter();
  public widgetUrl;
  public termsAndConditions: string = "";
  public loading: boolean = false;
  constructor(
    private settingsService: SettingsService,
    private mainService: MainService,
    private sanitizer: DomSanitizer
  ) {
    this.settingsService.getTermsAndConditions().subscribe((response) => {
      if (response.success) {
        this.termsAndConditions = response.terms_conditions;
        this.widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.termsAndConditions
        );
      }
    });
  }

  async ngOnInit() {}

  public onClose() {
    this.close.emit("Hide");
  }

  public onAcceptTerms() {
    this.loading = true;
    this.mainService
      .get({
        api: "users/accept-new-terms-conditions",
      })
      .subscribe(
        (response) => {
          this.loading = false;
          this.success.emit(response);
        },
        (err) => {
          console.log(err);
          this.loading = false;
          this.error.emit(err.error);
        }
      );
  }
}
